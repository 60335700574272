import Button from "core/components/new/Button"
import { CopyIcon, DeleteIcon, DownloadIcon, EditIcon } from "core/constants/svgs"
import useContestViewModel from "../ContestViewModel"

export default function ContestCard({ data, onView, onEdit, onDelete, onDuplicate, onResult }: any) {
  const duration = parseInt(data?.duration)
  const hours = Math.floor(duration / 60)
  const minutes = Math.floor(duration % 60)

  const formatDate = (timestamp: number) => {
    if (!timestamp) return ""

    const date = new Date(timestamp * 1000)
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = String(date.getFullYear()).slice(-2)
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")

    return `${day}/${month}/${year} ${hours}:${minutes}`
  }

  const { handleUpdatePlacementCohortBatch } = useContestViewModel()

  return (
    <div className="flex justify-between gap-6 p-6 shadow-[0px_3px_7px_0px_rgba(0,0,0,0.10)]">
      <div className="flex flex-col">
        <h3 className="text-new-accent">
          {data?.name}{" "}
          {data.batches && (
            <div className="inline-flex">
              {data?.batches?.map((batch: any, index: number) => (
                <div key={index} className="pl-2 inline-flex gap-2">
                  <span className="text-new-accent">|</span>
                  <span className="text-new-neutral-dark">{batch}</span>
                </div>
              ))}
            </div>
          )}
        </h3>
        <p className="">
          Duration: {hours > 0 && `${hours}hr${hours > 1 ? "s " : " "}`}
          {minutes > 0 && `${minutes}min${minutes > 1 ? "s" : ""}`}
          {data.start_time && ` | ${formatDate(data.start_time)}`}
        </p>
      </div>

      <div className="flex">
        <Button outlined className="mr-2 h-fit w-fit hover:text-white py-2.5" onClick={onView}>
          View
        </Button>
        <Button outlined className="mr-2 h-fit w-fit hover:text-white" onClick={onDuplicate}>
          <CopyIcon className={"w-5"} />
        </Button>
        <Button outlined className="mr-2 h-fit w-fit" onClick={onEdit}>
          <EditIcon className={"w-5"} />
        </Button>
        <Button
          outlined
          className="mr-2 h-fit w-fit border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
          onClick={onDelete}
        >
          <DeleteIcon className={"w-5"} />
        </Button>
        <Button outlined className="h-fit w-fit" onClick={onResult}>
          {/* http://localhost:8080/v4_admin/get_contest_result?contest_id=${data.id} */}
          <DownloadIcon className={"w-5"} />
        </Button>
      </div>
    </div>
  )
}
