import { Timelapse } from "@mui/icons-material"
import Information from "core/components/Information"
import Button from "core/components/new/Button"
import { Tooltip } from "core/components/v2/Tooltip"
import { STR_CANCELLED_TYPE, STR_PAST_TYPE, STR_UPCOMING_TYPE } from "core/constants/strings"
import { CalendarIcon, ClockIcon } from "core/constants/svgs"
import { isEmpty } from "core/utils/misc"
import { format, parseISO } from "date-fns"
import { useState } from "react"

export default function SessionCard({
  data,
  setMentorWaiting,
  type,
  onReview,
  onFeedback,
  onCancelled,
  onMenteeAbsent,
  setWaitingTime,
}: any) {
  const formatDate = (date: any) => {
    if (typeof date === "string") {
      // If the date is an ISO string, parse and format it
      return format(parseISO(date), "EEEE dd/LL/yyyy")
    } else if (typeof date === "number") {
      // If the date is a timestamp, convert and format it
      return format(new Date(date), "EEEE dd/LL/yyyy")
    } else {
      return "" // Return an empty string if the date is not valid
    }
  }

  const formatTime = (time: number | undefined) => {
    if (!time && time !== 0) return "" // handle undefined or null cases
    const hours = Math.floor(time) // Get the integer part (hours)
    const minutes = Math.round((time - hours) * 60) // Get the decimal part, convert to minutes

    // Format the time to ensure two digits for minutes
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`
  }
  const formattedDate = formatDate(data?.date || data?.session_date)
  const isMenteeAbsentAllowed = Date.now() >= data?.start_timestamp * 1000 + 15 * 60 * 1000

  const [intervalId, setIntervalId] = useState<any>(null)

  function formatFractionalTime(hour: string | number): string {
    const hour24 = parseFloat(hour.toString()) // Ensure it's a number
    const hours = Math.floor(hour24) // Extract the integer part (hours)
    const minutes = Math.round((hour24 % 1) * 60) // Convert the fractional part to minutes
    const formattedHours = hours.toString().padStart(2, "0") // Ensure two digits for hours
    const formattedMinutes = minutes.toString().padStart(2, "0") // Ensure two digits for minutes
    return `${formattedHours}:${formattedMinutes}` // Return time in "HH:MM" format
  }

  const handleJoinMeeting = () => {
    const currentDate = new Date()
    const sessionStartTime = data?.start_timestamp

    const timeDifference = sessionStartTime * 1000 + 15 * 60 * 1000 - currentDate.getTime() // add 15 mins extra

    const initialWaitingTime = timeDifference / 1000 // Set waiting time in seconds
    setWaitingTime(initialWaitingTime)

    // Clear any existing interval
    if (intervalId) {
      clearInterval(intervalId)
    }

    const newIntervalId = setInterval(() => {
      setWaitingTime((prevTime: any) => {
        if (prevTime <= 1) {
          clearInterval(newIntervalId)
          // clearInterval(joinInterval)
          return 0
        }
        return prevTime - 1 // Decrement by 1 second
      })
    }, 1000)

    // setIntervalId(joinInterval) // Store the new interval ID
  }

  const renderFeedbackStatus = (data: any) => {
    if (!isEmpty(data?.mentor_feedback)) {
      return (
        <div className="space-y-2">
          <p className="text-green-500 font-semibold">Feedback Provided</p>
        </div>
      )
    } else {
      return <p className="text-red-500 font-semibold">Feedback Not Provided</p>
    }
  }

  return (
    <div className="relative flex items-end justify-between p-6 text-new-neutral-dark shadow">
      <div className="absolute right-5 top-4">
        <Tooltip info={data?.agenda}>
          <Information className="size-[20px]" />
        </Tooltip>
      </div>
      <div className="space-y-4 w-full">
        <div className="flex w-full justify-between">
          <h3 className="text-new-accent">Mentor Session</h3>
        </div>
        <div className="space-y-1">
          <div className="flex items-center gap-2">
            <span>
              <CalendarIcon className="w-6" />
            </span>
            <p>{formattedDate}</p>
          </div>
          <div className="flex items-center gap-2">
            <span>
              <ClockIcon className="w-6" />
            </span>
            <p>
              {formatTime(data?.start || data?.session_start_time)} - {formatTime(data?.end || data?.session_end_time)}
            </p>
          </div>
          {type === STR_PAST_TYPE && (
            <div className="flex items-center gap-2">
              <span>
                <Timelapse className="w-6" />
              </span>
              <p>{data?.duration} minutes</p>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-y-0.5">
          <div className="inline-flex gap-1 text-sm">
            Student Email: <div className="font-semibold">{data?.student_id}</div>
          </div>
          <div className="inline-flex gap-1 text-sm">
            Student Name: <div className="font-semibold">{data?.name}</div>
          </div>
          <div className="inline-flex gap-1 text-sm">
            Batch: <div className="font-semibold">{data?.batch}</div>
          </div>
          <div className="inline-flex gap-1 text-sm">
            Phone: <div className="font-semibold">{data?.phone}</div>
          </div>
        </div>
      </div>
      {type !== STR_CANCELLED_TYPE && (
        <div className="flex items-center gap-2">
          {type === STR_UPCOMING_TYPE && (
            <>
              {/* {data?.id ? (
                <Button
                  disabled={!isMenteeAbsentAllowed || data?.session_status === "Mentee Absent"}
                  outlined
                  failure={isMenteeAbsentAllowed}
                  className="w-fit"
                  onClick={() => onMenteeAbsent(data?.id)}
                >
                  {data?.session_status === "Mentee Absent" ? "Mentee Absent" : "Mark Mentee Absent"}
                </Button>
              ) : (
                <></>
              )} */}
              <Button
                outlined
                failure
                className="w-fit"
                onClick={() => {
                  onCancelled()
                }}
              >
                Cancel Booking
              </Button>
              <Button
                outlined
                className="w-fit"
                onClick={() => {
                  handleJoinMeeting()
                  setMentorWaiting(data)
                  window.open(data?.meet_link, "_blank")
                }}
              >
                Join Meeting
              </Button>
            </>
          )}
          {type === STR_PAST_TYPE && (
            <>
              {/* {data?.id ? (
                <Button
                  disabled={!isMenteeAbsentAllowed || data?.session_status === "Mentee Absent"}
                  outlined
                  failure={isMenteeAbsentAllowed}
                  className="w-fit"
                  onClick={() => onMenteeAbsent(data?.id)}
                >
                  {data?.session_status === "Mentee Absent" ? "Mentee Absent" : "Mark Mentee Absent"}
                </Button>
              ) : (
                <></>
              )} */}
              <div className="flex flex-col w-[350px] items-center gap-2">{renderFeedbackStatus(data)}</div>

              <Button outlined className="w-fit" onClick={() => onReview(data?.index)}>
                View Review
              </Button>
              <Button
                outlined
                className="w-fit"
                onClick={() => {
                  setMentorWaiting({ ...data, edit: true })
                }}
              >
                {data?.mentor_feedback?.feedback || data?.mentor_feedback?.action_items || data?.mentor_feedback?.rating
                  ? "Edit Feedback"
                  : "Give Feedback"}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  )
}
