import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react"
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth"
import { Auth, User } from "domain/model/Auth"
import PageLoader from "core/components/PageLoader"
import { getUser } from "core/api/auth"
import { auth as firebaseAuth, provider } from "core/configs/firebase"
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth"
import { GetAdminLogin } from "domain/useCase/Admin/Login/GetAdminLogin"
import { AdminLoginAPIDataSourceImpl } from "data/API/Admin/AdminLoginAPIDataSourceImpl"
import { AdminLoginRepositoryImpl } from "data/repository/Admin/AdminLoginRepositoryImpl"
type AuthContextType = {
  loading: boolean
  auth: Auth
  user: User
  login: (email: string, password: string) => Promise<void>
  googleLogin: () => Promise<void>
  logout: () => Promise<void>
}

export const AuthContext = createContext<AuthContextType | null>(null)

type AuthProviderProps = { children: ReactNode }

const AUTH_REFRESH_INTERVAL = 10 * 60 * 1000 // 5 minutes

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, setAuth] = useState<Auth>({} as Auth)
  const [user, setUser] = useState<User>({} as User)
  const [loading, setLoading] = useState<boolean>(true)

  const GetAdminLoginUseCase = new GetAdminLogin(new AdminLoginRepositoryImpl(new AdminLoginAPIDataSourceImpl()))

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        const local_id = user.uid
        const id_token = await user.getIdToken()
        const { data } = await getUser(id_token)

        setAuth({ id_token, local_id })
        setUser(data)
      } else {
        localStorage.clear()
        setAuth({} as Auth)
        setUser({} as User)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (!auth || !firebaseAuth.currentUser) return

    const intervalId = setInterval(async () => {
      try {
        const refreshedToken = await firebaseAuth.currentUser?.getIdToken(true)
        if (refreshedToken) {
          setAuth((prev) => (prev ? { ...prev, id_token: refreshedToken } : prev))
        }
      } catch (error) {
        console.error("Error refreshing token:", error)
      }
    }, AUTH_REFRESH_INTERVAL)

    return () => clearInterval(intervalId)
  }, [auth])

  const login = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(firebaseAuth, email, password)
    } catch (e: unknown) {
      throw new Error("Unknown error occurred during login")
    }
  }

  const googleLogin = async () => {
    try {
      const result = await signInWithPopup(firebaseAuth, provider)
      const user = result.user
      const response = await GetAdminLoginUseCase.invoke(user.email as string)
      const data = response?.data?.isValid
      if (!data) {
        await user.delete()
        await signOut(firebaseAuth)
      }
    } catch (error) {
      throw new Error("Unknown error occurred during login")
    }
  }

  const logout = async () => {
    try {
      await signOut(firebaseAuth)
    } catch (e: unknown) {
      throw new Error("Error: unable to logout")
    }
  }

  const authContextValue = {
    auth,
    loading,
    user,
    login,
    logout,
    googleLogin,
  }

  return <AuthContext.Provider value={authContextValue}>{loading ? <PageLoader /> : children}</AuthContext.Provider>
}
