import React, { useState } from "react"
import { isEmpty } from "core/utils/misc"
import axios from "axios"
import { useAuth } from "core/context/auth"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import useToast from "core/hooks/useToast"
import Toast from "core/components/Toast"
import GetGoalSettingStatusUpdate from "domain/useCase/Mentor/Session/GetGoalSettingStatusUpdate"
import { SessionRepositoryImpl } from "data/repository/Mentor/SessionRepositoryImpl"
import SessionAPIDataSourceImpl from "data/API/Mentor/SessionAPIDataSourceImpl"

const GoalSettingSessionCard = ({ goalSettingSessions }: any) => {
  const getGoalSettingStatusUpdateUseCase = new GetGoalSettingStatusUpdate(
    new SessionRepositoryImpl(new SessionAPIDataSourceImpl())
  )

  const goalSettingStatusUpdate = async (id: string = "", status: string = "") => {
    const response = await getGoalSettingStatusUpdateUseCase.invoke(auth, id, status)
    return response
  }
  const [sessions, setSessions] = useState(goalSettingSessions)
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const handleStatusChange = async (id: string, newStatus: string) => {
    const updatedSessions = sessions.map((session: any) =>
      session.id === id ? { ...session, session_status: newStatus } : session
    )
    setSessions(updatedSessions)

    try {
      // await axios.post(
      //   `http://localhost:8000/admin/goal-setting-status-update?id=${id}`,
      //   { status: newStatus },
      //   { Authorization: `Bearer ${auth.id_token}` }
      // )

      await goalSettingStatusUpdate(id, newStatus.valueOf())

      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, "Goal setting session updated successfully.")
    } catch (error) {
      console.error("Error updating session status:", error)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error updating goal setting session.")
    }
  }

  const getOptionStyle = (status: string) => {
    switch (status) {
      case "Scheduled Session":
        return "text-purple-500"
      case "Completed":
        return "text-green-600"
      case "Cancelled by Mentor":
        return "text-red-500"
      case "Cancelled by Mentee":
        return "text-orange-500"
      case "Taken Elsewhere":
        return "text-blue-600"
      case "Mentor Not Joined":
      case "Mentor Absent":
        return "text-pink-500"
      case "Mentee Not Joined":
      case "Mentee Absent":
        return "text-yellow-500"
      default:
        return "text-gray-500"
    }
  }

  const STATUSARRAY: string[] = [
    "Scheduled Session",
    "Completed",
    "Cancelled by Mentor",
    "Cancelled by Mentee",
    "Taken Elsewhere",
    "Mentor Not Joined",
    "Mentee Not Joined",
  ]

  const sessionStatus = (data: any) => {
    if (data?.duration > 0) {
      return "Completed"
    }
    return data?.session_status ?? data?.status ?? "Scheduled Session"
  }

  const renderFeedbackStatus = (data: any) => {
    if (!isEmpty(data?.mentor_feedback)) {
      return (
        <div className="space-y-2">
          <p className="text-green-500 font-semibold">Feedback Provided</p>
        </div>
      )
    } else {
      return <p className="text-red-500 font-semibold">Feedback Not Provided</p>
    }
  }

  return (
    <div className="space-y-4">
      {!isEmpty(sessions) ? (
        sessions.map((data: any, i: number) => (
          <div key={i} className="flex justify-between px-6 py-4 text-new-neutral-dark shadow">
            <div className="flex items-end gap-x-6">
              <div className="space-y-1">
                <h4 className="mb-1">{data.name}</h4>
                <div className="flex gap-x-4">
                  {!isEmpty(data.student_id) ? (
                    <p>{data.student_id || "No Student Found"}</p>
                  ) : (
                    <p className="italic">No Student Found</p>
                  )}
                  <p>{data.batch}</p>
                </div>
                <p>{data.session_date}</p>
              </div>
            </div>
            <div className="flex w-[190px] flex-col">{renderFeedbackStatus(data)}</div>
            <div className="flex gap-x-12">
              <div className="flex flex-col gap-y-5">
                {data.new_type?.includes("extra_goal_setting") ? (
                  <div className={`p-2 w-64 border-2 border-gray-500 rounded ${getOptionStyle("Completed")}`}>
                    Completed
                  </div>
                ) : (
                  <select
                    value={sessionStatus(data)}
                    onChange={(e) => handleStatusChange(data.id, e.target.value)}
                    className={`border-2 rounded p-2 w-64 ${getOptionStyle(sessionStatus(data))}`}
                  >
                    {STATUSARRAY.map((item: string, index: number) => (
                      <option key={index} value={item} className={getOptionStyle(item)}>
                        {item === "Mentee Absent"
                          ? "Mentee Not Joined"
                          : item === "Mentor Absent"
                            ? "Mentor Not Joined"
                            : item}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No goal setting sessions found.</p>
      )}
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}

export default GoalSettingSessionCard
