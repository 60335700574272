import { useApp } from "core/context/app"
import { useAuth } from "core/context/auth"
import NotificationAPIDataSourceImpl from "data/API/Student/NotificationAPIDataSourceImpl"
import { NotificationRepositoryImpl } from "data/repository/Student/NotificationRepository"
import { UpdateNotificationRead } from "domain/useCase/Student/Notification/UpdateNoticationRead"
import React from "react"
import { useNavigate } from "react-router-dom"
import searchMapping from "./utils/searchMapping"

interface NavigationRouteInterface {
  [key: string]: string
}

const ViewModel = () => {
  const { auth, logout } = useAuth()
  const {
    notificationDetails,
    notificationBubble,
    student,
    setNotificationDetails,
    fetchNotificationDetails,
    setNotificationBubble,
    setActiveButton,
  } = useApp()

  const [open, setOpen] = React.useState(false)
  const [dropdownVisible, setDropdownVisible] = React.useState(false)
  const [notificationVisible, setNotificationVisible] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState<string>("")
  const [filteredList, setFilteredList] = React.useState<string[]>([])
  const [deleteModel, setDeleteModel] = React.useState(false)

  const navigate = useNavigate()

  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const notificationRef = React.useRef<HTMLDivElement>(null)

  const isAccelerator = student?.batch?.includes("Accelerator")
  const isKCE = student?.batch?.includes("KCE")
  const isEV = student?.batch?.includes("EV")
  const isDSML = student?.batch?.includes("DSML")

  const UpdateNotificationReadUseCase = new UpdateNotificationRead(
    new NotificationRepositoryImpl(new NotificationAPIDataSourceImpl())
  )

  const toggleDropdown = () => {
    setDropdownVisible(true)
  }

  const toggleNotification = () => {
    setNotificationVisible(true)
  }

  const handleSearchModal = () => {
    setOpen(!open)
  }

  const ProfileMenuListData = [
    // "User Profile",
    !isAccelerator && !isDSML && "Progress Report",
    !(isKCE || isAccelerator) && "Batch Change",
    !(isAccelerator || isKCE) && "Mentor Change",
    "Referral Code",
    student?.tracks?.includes("software_engineering") && "Leetcode Id",
    "Doubt Support",
    "Reset Password",
  ]

  const NavigationRoute: NavigationRouteInterface = {
    "Onboarding Team": "/home?step=1",
    "Fee Payment": "/transaction",
    "Bosscoder Community": "/home?step=4",
    "Course Prerequisite": `/home?step=${isAccelerator ? "4" : isKCE ? "2" : isEV ? "5" : "6"}`,
    "Goal Setting Mentor Session": "/mentor",
    "Mentor Session": "/mentor",
    // "Onboarding Meeting": "",
    // "Course Orientation Session": "",
    "Mentor Change": "/doubt-support?topic=Mentor Change",
    "Placement Cohort Test": "/weekly-test-series",
    "Student Progress Meeting": "/progress-meeting",
    // "Batch Start Date": "",
    "Course Curriculum": "/course",
    // "Master Classes": "",
    "Upcoming Classes": "/course?tab=Upcoming Classes",
    "Past Classes": "/course?tab=Past Classes",
    "Class Attendance": "/course?tab=Past Classes",
    "Class Feedback": "/course?tab=Past Classes",
    "Past Class Recordings": "/course?tab=Past Classes",
    "Class Assignments": "/course?tab=Past Classes",
    "Classes Schedule": "/schedule",
    Tests: "/weekly-test-series",
    Leaderboard: "/leaderboard",
    Placement: "/placement",
    "Redeem Coins": "/store",
    "Fee Payment Methods": "/payment",
    "Refer and Earn": "/refer",
    "Doubt Support": "/doubt-support",
    "Batch Change": "/doubt-support?topic=Batch Change",
    "Course Pause": "/doubt-support?topic=Course Pause",
    "Resume Course": "/doubt-support?topic=Resume Course",
    "Reset Password": "/password_reset",
  }

  const handleSelectedSearch = (selected: string) => {
    const route = NavigationRoute[selected]
    setOpen(false)
    if (route) {
      // setActiveButton(selected)

      navigate(route)
    }
  }

  const handleLogout = async () => {
    await logout()
    window.webengage.user.logout()
    navigate("/login", { replace: true })
  }

  const handleProfileMenubuttonsClick = (selected: string, onLeetcodeChange: any) => {
    if (selected === "Batch Change") {
      navigate("/doubt-support?topic=Batch Change")
    } else if (selected === "Referral Code") {
      navigate("/refer")
    } else if (selected === "Mentor Change") {
      navigate("/doubt-support?topic=Mentor Change")
    } else if (selected === "User Profile") {
      navigate("/home")
    } else if (selected === "Progress Report") {
      navigate("/student-progress")
    } else if (selected === "Doubt Support") {
      navigate("/doubt-support")
    } else if (selected === "Reset Password") {
      navigate("/password_reset")
    } else if (selected === "Leetcode Id") {
      setDropdownVisible(false)
      onLeetcodeChange(true)
    } else if (selected === "Sign out") {
      // navigate("/logout")
      setDeleteModel(true)
    }
  }
  const handleNotificationRedirect = async (index: number) => {
    switch (notificationDetails[index].type) {
      case "class":
        notificationDetails[index].isRead = true
        const reqObjClass = { ...notificationDetails[index] }
        delete reqObjClass.type
        await UpdateNotificationReadUseCase.invoke(auth, {
          class: reqObjClass,
        })
        navigate("/course")

        break
      case "contest":
        notificationDetails[index].isRead = true
        const reqObjContest = { ...notificationDetails[index] }
        delete reqObjContest.type
        await UpdateNotificationReadUseCase.invoke(auth, {
          contest: reqObjContest,
        })
        navigate("/weekly-test-series")
        break
      case "progress_meet":
        notificationDetails[index].isRead = true
        const reqObjProgress = { ...notificationDetails[index] }
        delete reqObjProgress.type
        await UpdateNotificationReadUseCase.invoke(auth, {
          progress_meet: reqObjProgress,
        })
        navigate("/progress-meeting")
        break
      case "schedule":
        notificationDetails[index].isRead = true
        const reqObjSchedule = { ...notificationDetails[index] }
        delete reqObjSchedule.type
        await UpdateNotificationReadUseCase.invoke(auth, {
          schedule: reqObjSchedule,
        })
        navigate("/schedule")
        break
      case "mentor":
        notificationDetails[index].isRead = true
        const reqObjMentor = { ...notificationDetails[index] }
        delete reqObjMentor.type
        await UpdateNotificationReadUseCase.invoke(auth, {
          mentor: reqObjMentor,
        })

        navigate("/mentor")
        break
      case "leaderboard":
        notificationDetails[index].isRead = true
        const reqObjLeaderboard = { ...notificationDetails[index] }
        delete reqObjLeaderboard.type
        await UpdateNotificationReadUseCase.invoke(auth, {
          leaderboard: reqObjLeaderboard,
        })
        navigate("/leaderboard")
        break
      case "placement":
        notificationDetails[index].isRead = true
        const reqObjPlacement = { ...notificationDetails[index] }
        delete reqObjPlacement.type
        await UpdateNotificationReadUseCase.invoke(auth, {
          placement: reqObjPlacement,
        })
        navigate("/placement")
        break
      default:
        break
    }

    let isAllRead = true
    notificationDetails.forEach((notification: any) => {
      if (!notification.isRead) {
        isAllRead = false
      }
    })
    setNotificationBubble(!isAllRead)

    // Update the localStorage with the new notification details
    setNotificationDetails(notificationDetails)
  }

  return {
    auth,
    searchQuery,
    dropdownVisible,
    notificationVisible,
    notificationDetails,
    open,
    ProfileMenuListData,
    notificationBubble,
    dropdownRef,
    notificationRef,
    searchMapping,
    handleNotificationRedirect,
    fetchNotificationDetails,
    setSearchQuery,
    setOpen,
    setNotificationVisible,
    setDropdownVisible,
    handleSearchModal,
    toggleNotification,
    handleSelectedSearch,
    toggleDropdown,
    filteredList,
    setFilteredList,
    handleProfileMenubuttonsClick,
    deleteModel,
    setDeleteModel,
    handleLogout,
  }
}

export default ViewModel
