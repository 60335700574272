import BarGraph from "assets/svgs/BarGraph"
import Book from "assets/svgs/Book"
import Crown from "assets/svgs/Crown"
import Recording from "assets/svgs/Recording"

const BriefProgressDataView = ({
  fromDate,
  toDate,
  progress,
  detailedReportHandler,
}: {
  fromDate: string | undefined
  toDate: string | undefined
  progress: any
  detailedReportHandler: any
}) => {
  return (
    <div className="py-[16px] px-[19px] w-[100%] rounded-[8px] border-[0.5px] border-[#D3D3D3] my-[24px]">
      <p className="text-[#333] text-[14px] font-[600]">
        Progress Report ( {fromDate} - {toDate} )
      </p>
      <div className="flex gap-[16px] my-[16px]">
        <div className="h-[200px] p-[12px] border-[1px] border-[#D3D3D3] w-1/4 rounded-[8px]">
          <div>
            <Recording color={"#1C3474"} />
          </div>
          <p className="text-[10px] font-[600] text-[#162456]">Classes</p>
          <hr className="my-[8px]" />
          <div className="flex flex-col gap-1">
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Present</p>
              <p className="w-[50px] text-[#333333] font-medium flex justify-center">{progress?.classes?.present}</p>
            </div>
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Absent</p>
              <p className="w-[50px] flex justify-center font-medium text-[#DC2626]">{progress?.classes?.absent}</p>
            </div>
          </div>
        </div>
        <div className="h-[200px] p-[12px] border-[1px] border-[#D3D3D3] w-1/4 rounded-[8px]">
          <div>
            <Crown color={"#1C3474"} />
          </div>
          <p className="text-[10px] font-[600] text-[#162456]">Mentor Sessions</p>
          <hr className="my-[8px]" />
          <div className="flex flex-col gap-1">
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Completed</p>
              <p className="w-[50px] flex justify-center font-medium bg-[#EDFFF2] text-[#07B42D]">
                {progress?.mentorSessions?.completed}
              </p>
            </div>
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Mentee Absent</p>
              <p className="w-[50px] flex justify-center font-medium text-[#DC2626]">
                {progress?.mentorSessions?.menteeAbsent}
              </p>
            </div>
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Mentor Absent</p>
              <p className="w-[50px] flex font-medium justify-center text-[#DC2626]">
                {progress?.mentorSessions?.mentorAbsent}
              </p>
            </div>
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Canceled-Mentor</p>
              <p className="w-[50px] flex font-medium justify-center text-[#DC2626]">
                {progress?.mentorSessions?.cancelledByMentor}
              </p>
            </div>
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Canceled-Mentee</p>
              <p className="w-[50px] flex font-medium justify-center text-[#DC2626]">
                {progress?.mentorSessions?.cancelledByMentee}
              </p>
            </div>
          </div>
        </div>
        <div className="h-[200px] p-[12px] border-[1px] border-[#D3D3D3] w-1/4 rounded-[8px]">
          <div>
            <Book color={"#1C3474"} />
          </div>
          <p className="text-[10px] font-[600] text-[#162456]">Exams</p>
          <hr className="my-[8px]" />
          <div className="flex flex-col gap-1">
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Attempted</p>
              <p className="w-[50px] flex font-medium justify-center text-[#333333]">{progress?.exams?.present}</p>
            </div>
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Absent</p>
              <p className="w-[50px] flex font-medium justify-center text-[#DC2626]">{progress?.exams?.absent}</p>
            </div>
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Passed</p>
              <p className="w-[50px] flex font-medium justify-center text-[#07B42D]">{progress?.exams?.pass}</p>
            </div>
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Failed</p>
              <p className="w-[50px] flex font-medium justify-center text-[#DC2626]">{progress?.exams?.fail}</p>
            </div>
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Avg. Score</p>
              <p className="w-[50px] flex font-medium justify-center text-[#333333]">
                {progress?.exams?.averageTotalScore === 0
                  ? 0
                  : (progress?.exams?.averageScore * 100) / progress?.exams?.averageTotalScore}
                %
              </p>
            </div>
          </div>
        </div>
        <div className="h-[200px] p-[12px] border-[1px] border-[#D3D3D3] w-1/4 rounded-[8px]">
          <div>
            <BarGraph color={"#1C3474"} />
          </div>
          <p className="text-[10px] font-[600] text-[#162456]">Problem Solving</p>
          <hr className="my-[8px]" />
          <div className="flex flex-col gap-1">
            <div className="flex justify-between text-[#646464] text-[12px] font-[500]">
              <p>Attempted</p>
              <p className="w-[50px] flex font-medium justify-center text-[#333333]">{progress?.attempted}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex justify-center items-center text-[12px] font-[500] h-[31px] bg-[#F9F9F9] rounded-[8px] cursor-pointer"
        onClick={detailedReportHandler}
      >
        View Detailed Report
      </div>
    </div>
  )
}

export default BriefProgressDataView
