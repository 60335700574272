import { LineChartIcon } from "core/constants/svgs"
import { CustomScroll } from "react-custom-scroll"

const DetailedProgressDataView = ({
  detailedProgress,
  activeButton,
  month,
  year,
  showFirst6Months,
}: {
  detailedProgress: any[]
  activeButton: string
  month: number | null
  year: number
  showFirst6Months: boolean
}) => {
  return (
    <div className="w-[350px] p-4 rounded-lg shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)] border border-[#d3d3d3]">
      <div className="flex justify-between items-bottom mb-2">
        <div className="text-[#1b3473] text-base font-semibold">{activeButton}</div>
        <div className="w-6 h-6 p-1 bg-[#f8f8f8] rounded justify-center items-center inline-flex overflow-hidden">
          <div>
            <LineChartIcon className="w-4 h-4" />
          </div>
        </div>
      </div>
      <CustomScroll heightRelativeToParent="100%" className="w-[calc(100%+0.75rem)] max-h-[calc(100%-1.25rem)]">
        <div className="flex w-[calc(100%-0.75rem)] flex-wrap gap-2 py-2">
          {detailedProgress.map((item: any, index: any) =>
            activeButton === "Classes Scheduled" || activeButton === "Problems Solved" ? (
              <div className="w-[calc(33%-5px)] p-2 rounded-lg border border-[#d3d3d3] flex flex-col gap-1">
                <div className="self-stretch text-[#1b3473] text-[15px] font-semibold">
                  {activeButton === "Classes Scheduled" &&
                    (item.progress.classes.present + item.progress.classes.absent === 0
                      ? "N/A"
                      : `${item.progress.classes.present} / ${item.progress.classes.present + item.progress.classes.absent}`)}
                  {activeButton === "Problems Solved" && item.progress.attempted}
                </div>
                <div className="self-stretch text-[#636363] text-xs font-medium">
                  {month !== null
                    ? `${item.name} . ${new Date(year, month, 1).toLocaleString("default", { month: "short" })}`
                    : `${new Date(year, showFirst6Months ? index : index + 6, 1).toLocaleString("default", { month: "long" })}`}
                </div>
              </div>
            ) : (
              <div className="w-[calc(50%-4px)] p-2 gap-2 border border-[#d3d3d3] rounded-lg flex flex-col">
                <div className="text-[#333232] text-sm font-semibold">
                  {month !== null
                    ? `${item.name} . ${new Date(year, month, 1).toLocaleString("default", { month: "short" })}`
                    : `${new Date(year, showFirst6Months ? index : index + 6, 1).toLocaleString("default", { month: "long" })}`}
                </div>
                <div className="w-full bg-[#d3d3d3] h-[1px]"></div>
                <div className="flex flex-col gap-1 justify-between">
                  {activeButton === "Mentor Sessions" ? (
                    <>
                      <div className="flex justify-between text-[#646464]">
                        <p className="text-xs font-medium">Completed</p>
                        <p className="text-xs font-medium w-[30px] flex justify-center bg-[#EDFFF2] text-[#07B42D]">
                          {item.progress?.mentorSessions?.completed}
                        </p>
                      </div>
                      <div className="flex justify-between text-[#646464]">
                        <p className="text-xs font-medium">Mentee Absent</p>
                        <p className="text-xs font-medium w-[30px] flex justify-center text-[#DC2626]">
                          {item.progress?.mentorSessions?.menteeAbsent}
                        </p>
                      </div>
                      <div className="flex justify-between text-[#646464]">
                        <p className="text-xs font-medium">Mentor Absent</p>
                        <p className="text-xs font-medium w-[30px] flex justify-center text-[#DC2626]">
                          {item.progress?.mentorSessions?.mentorAbsent}
                        </p>
                      </div>
                      <div className="flex justify-between text-[#646464]">
                        <p className="text-xs font-medium">Canceled-Mentor</p>
                        <p className="text-xs font-medium w-[30px] flex justify-center text-[#DC2626]">
                          {item.progress?.mentorSessions?.cancelledByMentor}
                        </p>
                      </div>
                      <div className="flex justify-between text-[#646464]">
                        <p className="text-xs font-medium">Canceled-Mentee</p>
                        <p className="text-xs font-medium w-[30px] flex justify-center text-[#DC2626]">
                          {item.progress?.mentorSessions?.cancelledByMentee}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-between text-[#646464]">
                        <p className="text-xs font-medium">Attempted</p>
                        <p className="text-xs font-medium w-[30px] flex justify-center text-[#333333]">
                          {item.progress?.exams?.present}
                        </p>
                      </div>
                      <div className="flex justify-between text-[#646464]">
                        <p className="text-xs font-medium">Absent</p>
                        <p className="text-xs font-medium w-[30px] flex justify-center text-[#DC2626]">
                          {item.progress?.exams?.absent}
                        </p>
                      </div>
                      <div className="flex justify-between text-[#646464]">
                        <p className="text-xs font-medium">Passed</p>
                        <p className="text-xs font-medium w-[30px] flex justify-center text-[#07B42D]">
                          {item.progress?.exams?.pass}
                        </p>
                      </div>
                      <div className="flex justify-between text-[#646464]">
                        <p className="text-xs font-medium">Failed</p>
                        <p className="text-xs font-medium w-[30px] flex justify-center text-[#DC2626]">
                          {item.progress?.exams?.fail}
                        </p>
                      </div>
                      <div className="flex justify-between text-[#646464]">
                        <p className="text-xs font-medium">Avg. Score</p>
                        <p className="text-xs font-medium w-[30px] flex justify-center text-[#333333]">
                          {item.progress?.exams?.averageTotalScore === 0
                            ? 0
                            : (item.progress?.exams?.averageScore * 100) / item.progress?.exams?.averageTotalScore}
                          %
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </CustomScroll>
    </div>
  )
}

export default DetailedProgressDataView
